import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
// import { patchFormNewData, putJsonData } from "../../Services/AxoisInterceptorr";
// import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";

// import { SmallButton } from "../coustombutton/SmallButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomInputLabel from "../styledCcomponents/styledInput";
import { DescriptionInput } from "../styledCcomponents/StyledMenu";
import MarqetingEndpoint from "../../Services/marqetingEndpoint";
import { postFormData } from "../../Services/AxoisInterceptorr";
import { validateInput } from "../../common/utils/validationUtils";
import { PATTERNS } from "../../common/utils/constant";
import { useCountries } from "../customHooks/customHook";
import ReCAPTCHA from "react-google-recaptcha";

const ApplyJobPopUp = ({
  open,
  onClose,
  row,

  onSuccess,
}) => {
  const [formData, setFormData] = useState({
    firstname: {
      value: "",
      isValid: true,
    },
    email: {
      value: "",
      isValid: true,
    },
    phone: {
      value: "",
      isValid: true,
    },
  });
  const [capVal, setCapVal] = useState(null);

  const [selectedImages, setSelectedImages] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const [error, setErorr] = useState("");

  const countries = useCountries();

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid =
      formData.firstname.isValid &&
      formData.email.isValid &&
      formData.phone.isValid && selectedImages;

    if (isFormValid) {
      const data = {
        fullName: formData.firstname.value,
        email: formData.email.value,
        mobileNumber: formData.phone.value,
        jobRole: row,
      };
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      if (selectedImages) {
        newData.append("resume", selectedImages);
      }
      let endpoint = MarqetingEndpoint.CAREER_CREATE;

      postFormData(
        endpoint,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              onClose();
              setCapVal(null)
              // onSuccess && onSuccess();
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          // onClose();
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };

  const handleReviewScreenShot = (e) => {
    setSelectedImages(e.target.files[0]);
  };


  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  return (
    <>
      {" "}
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "90%", md: "800px" },

            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            background: "#FFFFFF",
            padding: "30px",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CloseIcon
              onClick={onClose}
              sx={{
                color: "red",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            />
          </div>
          <div style={{ overflow: "auto", maxHeight: "80vh" }}>
          <h2 className="admin_note_heading">Job Application Form</h2>
          <form onSubmit={handleSubmit}>
            <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Full Name*</CustomInputLabel>
                  <DescriptionInput
                    required
                    name="firstname"
                    type="text"
                    placeholder="Enter Full Name"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "firstname", PATTERNS.PERSONNEW_NAME);
                    }}
                  />
                  {formData.firstname.isValid || (
                    <p className="input_text_error">
                      *Please enter your full name.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Email Address*</CustomInputLabel>
                  <DescriptionInput
                    required
                    name="email"
                    type="text"
                    placeholder="Enter Email Address"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "email", PATTERNS.EMAIL);
                    }}
                  />
                  {formData.email.isValid || (
                    <p className="input_text_error">
                      *Please enter your valid email address.
                    </p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Phone Number*</CustomInputLabel>
                  <DescriptionInput
                    required
                    name="phone"
                    type="number"
                    placeholder="Enter Phone Number"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "phone", PATTERNS.MOBILE);
                    }}
                  />
                  {formData.phone.isValid || (
                    <p className="input_text_error">
                      *Please enter your phone number.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Role*</CustomInputLabel>
                  <DescriptionInput
                    name="first_name"
                    type="text"
                    id="outlined-required"
                    label="First Name"
                    helper
                    value={row}
                    size="small"
                    readOnly
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <div className="mobile_function">
                    <CustomInputLabel>Resume*</CustomInputLabel>
                  </div>

                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={handleReviewScreenShot}
                  />
                  {formSubmitted && !selectedImages &&(
                    <p className="input_text_error">
                      *Please upload the resume.
                    </p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
         
            <Grid item xs={12} sm={12}>
            <div style={{marginBottom:"15px",marginTop:"5px"}}>
              <ReCAPTCHA
                sitekey="6LcTQy0pAAAAAJwipNtK-1Cyfscd0DOsb-YUhqgx"
                // sitekey="6LfMJR8pAAAAANOgSmosSeJEHnyoi7Gmu2EiS2Q1"
                onChange={(val) => setCapVal(val)}
              /></div>
              {capVal ? (
                <button
                  className="apply_button_job"
                  type={isDisabledNew ? "button" : "submit"}
                  disabled={isDisabledNew}
                >
                  {isDisabledNew ? "Loading..." : "Submit"}
                </button>
              ) : (
                <button disabled className="apply_button_job_disabled">
                   Submit
                </button>
              )}
            </Grid>
          </form>    </div>
        </Box>
      </Modal>
    </>
  );
};

export default ApplyJobPopUp;
